const ProjectImg = ({ src, alt, ...children }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="hover:animate-wiggle hover:animate-thrice ring-borderbeige mb-8 rounded-lg ring-4"
      {...children}
    />
  );
};

export default ProjectImg;
