import { useState } from "react";

const Navigation = () => {
  const [navBar, setNavBar] = useState(false);

  const handleOpenNavBar = () => {
    setNavBar(true);
  };

  const handleCloseNavBar = () => {
    setNavBar(false);
  };

  return (
    <div className="mt-14 flex">
      <div
        className={`mr-4 flex gap-8 overflow-hidden transition-all duration-1000 ${
          navBar ? "w-96" : "w-0"
        }`}
        onMouseEnter={handleOpenNavBar}
        onMouseLeave={handleCloseNavBar}
      >
        <p className="hover:cursor-pointer">About</p>
        <p className="hover:cursor-pointer">Projects</p>
        <p className="hover:cursor-pointer">Jobs</p>
        <p className="hover:cursor-pointer">Contact</p>
        <p className="hover:cursor-pointer">Resume</p>
      </div>
      <div>
        <span
          class="material-symbols-outlined mr-20 scale-150 transition-all duration-300 hover:rotate-90 hover:cursor-pointer "
          onMouseEnter={handleOpenNavBar}
          // onMouseLeave={handleCloseNavBar}
        >
          menu
        </span>
      </div>
    </div>
  );
};

export default Navigation;
