const ProjectA = ({ href, children }) => {
  return (
    <a
      href={href}
      className="hover:underline"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
export default ProjectA;
