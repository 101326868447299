const About = () => {
  return (
    <div className="p-24">
      <h2 className="mb-8 text-5xl">About Me</h2>
      <h3 className="leading-loose">
        <div className="mb-2">
          <span className="text-3xl">
            Full Stack Developer who is passionate about crafting creative
            solutions to complex problems. <br />
          </span>
        </div>
        <span className="text-xl">
          With over 10 years of experience in the retail world managing various
          areas including Risk Management, Food Service and Logistics. I have a
          deep understanding of how to lead a team and drive the business. Now
          I'm looking to bring my strong analytical skills and attention to
          detail to various facets of software development.
        </span>
      </h3>
    </div>
  );
};

export default About;
