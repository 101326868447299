import ProjectA from "./StyleComponents/ProjectA";
import ProjectDiv from "./StyleComponents/ProjectDiv";
import ProjectImg from "./StyleComponents/ProjectImg";
import ProjectP from "./StyleComponents/ProjectP";

const Projects = () => {
  return (
    <div>
      <h1 className="px-24 text-5xl leading-loose">Featured Projects</h1>
      <div className="grid grid-cols-2 grid-rows-2 gap-8 px-24">
        {/****** Lego Terrarium card ******/}
        <ProjectDiv>
          <a
            href="https://jazzy-pothos-85e300.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProjectImg
              src="https://i.imgur.com/aGOjkeV.png"
              alt="lego terrarium landing page"
            />
          </a>
          <ProjectP>React | Redux-Toolkit | Django | SQL | Tailwinds</ProjectP>
          <ProjectP>
            GitHub:&nbsp;
            <ProjectA href={"https://github.com/sicao1/lego_frontend"}>
              Frontend
            </ProjectA>
            <span>&nbsp;|&nbsp;</span>
            <ProjectA href={"https://github.com/sicao1/lego_backend"}>
              Backend
            </ProjectA>
          </ProjectP>
        </ProjectDiv>

        {/****** PawsonLife card ******/}
        <ProjectDiv>
          <ProjectA href={"https://pawson.life/"}>
            <ProjectImg
              src="https://i.imgur.com/57ewNRk.png"
              alt="pawson.life landing page"
            />
          </ProjectA>
          <ProjectP>React | MUI | MongoDB | Express | Google Maps</ProjectP>
          <ProjectP>
            GitHub:&nbsp;
            <ProjectA
              href={"https://github.com/sicao1/pawsOnLifeFrontEnd/tree/main"}
            >
              Frontend
            </ProjectA>
            <span>&nbsp;|&nbsp;</span>
            <ProjectA href={"https://github.com/sicao1/pawsOnLifeBackEnd"}>
              Backend
            </ProjectA>
          </ProjectP>
        </ProjectDiv>

        {/****** Mortal Kombat card ******/}
        <ProjectDiv>
          <ProjectA href={"https://classy-sundae-1956f7.netlify.app/"}>
            <ProjectImg
              src="https://i.imgur.com/11CbxNs.png"
              alt="mortal kombat fast food edition landing page"
            />
          </ProjectA>
          <ProjectP>JavaScript | HTML | CSS</ProjectP>
          <ProjectP>
            <ProjectA href={"https://github.com/sicao1/Project_1_The_Game"}>
              GitHub
            </ProjectA>
          </ProjectP>
        </ProjectDiv>

        {/****** NASA card ******/}
        <ProjectDiv>
          <ProjectA href={"https://serene-sprinkles-fcb288.netlify.app/"}>
            <ProjectImg
              src="https://i.imgur.com/AhkWPBs.png"
              alt="celestial delights landing page"
            />
          </ProjectA>
          <ProjectP> HTML | CSS | DOM | API Fetch</ProjectP>
          <ProjectP>
            <ProjectA
              href={"https://github.com/sicao1/nasa_pic_of_the_day_app"}
            >
              GitHub
            </ProjectA>
          </ProjectP>
        </ProjectDiv>
      </div>
    </div>
  );
};

export default Projects;
