// import "./App.css";

import About from "./components/About";
// import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Projects from "./components/Projects";

function App() {
  return (
    <div className="relative">
      <div className="bg-lightpink pb-18 absolute left-0 right-0 top-0 z-20 flex justify-between">
        <Header />
        <Navigation />
      </div>
      <div className="bg-lightpink skew-y-17 relative -top-28 z-10 p-48"></div>
      <div className="relative -top-64 z-20">
        <About />
      </div>
      <div className="bg-lightbeige relative -top-56 pb-20 ">
        <Projects />
      </div>
      <div className="relative -top-56">
        <Footer />
      </div>
    </div>
  );
}

export default App;
