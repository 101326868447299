const Footer = () => {
  const handleDownload = () => {
    const resumeUrl =
      process.env.PUBLIC_URL + "/Singa Cao - ATS Resume Template.pdf";
    window.open(resumeUrl, "_blank");
  };

  return (
    <div id="footer" className="p-24">
      <div className="mb-8 text-center">
        <h1 className="text-4xl">Let's get in touch</h1>
      </div>

      <div className="flex justify-evenly">
        <div className="flex items-center gap-1">
          <ion-icon name="logo-github"></ion-icon>
          <a
            href="https://github.com/sicao1"
            target="_blank"
            rel="noopener noreferrer"
          >
            github
          </a>
        </div>

        <div className="flex items-center gap-1">
          <ion-icon name="logo-linkedin"></ion-icon>
          <a
            href="https://www.linkedin.com/in/singacao"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </a>
        </div>

        <div className="flex items-center gap-1">
          <ion-icon name="mail"></ion-icon>
          <a href="mailto:sicao384@gmail.com">sicao384@gmail.com</a>
        </div>

        <div className="flex items-center gap-1">
          <ion-icon name="cloud-download"></ion-icon>
          <button onClick={handleDownload}>Download Resume</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
